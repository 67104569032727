import SessionHelper from "./SessionHelper";
import axios from "axios";
import { BASE_URL } from "../config";

/**
 * This class layer for API connection
 */
class ApiHelper {
  accessToken = null;
  defaultOption = null;

  constructor() {
    if (SessionHelper.getIsLoggedIn()) {
      this.accessToken = SessionHelper.user.accessToken;
    }
    this.defaultOption = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": `${this.accessToken}`,
      },
    };
  }

  setAccessToken(token) {
    this.accessToken = token;
  }

  /**
   * post request to API
   * @param {string} endpoint is endpoint of the service
   * @param {any} body data which is sent to API
   * @param {number} contentType content type of http request header
   */
  async post(endpoint, body) {
    const option = this.defaultOption;
    try {
      const response = await axios.post(`${BASE_URL}${endpoint}`, body, option);
      return response.data;
    } catch (e) {
      return { Data: null, Status: 3, Message: "Beklenmedik bir hata oluştu!" };
    }
  }

  async patch(endpoint, body) {
    const option = this.defaultOption;
    console.log(option);
    try {
      const response = await axios.patch(`${BASE_URL}${endpoint}`, body, option);
      return response.data;
    } catch (e) {
      return { Data: null, Status: 3, Message: "Beklenmedik bir hata oluştu!" };
    }
  }

  async postFormData(endpoint, body) {
    const option = {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": `${this.accessToken}`,
      },
    };
    try {
      const response = await axios.post(`${BASE_URL}${endpoint}`, body, option);
      return response.data;
    } catch (e) {
      return { Data: null, Status: 3, Message: "Beklenmedik bir hata oluştu!" };
    }
  }

  async get(endpoint) {
    const option = this.defaultOption;
    try {
      const response = await axios.get(`${BASE_URL}${endpoint}`, option);
      if (response.status === 200) {
        return {
          data: response.data,
          success: true,
        };
      }
      return {
        data: response?.data?.Message,
        success: false,
      };
    } catch (e) {
      return {
        success: false,
        data: e?.response?.data?.Message,
      };
    }
  }

  async delete(endpoint) {
    const option = this.defaultOption;
    try {
      const response = await axios.delete(`${BASE_URL}${endpoint}`, option);
      if (response.status === 200) {
        return {
          data: response.data,
          success: true,
        };
      }
      return {
        data: response?.data?.Message,
        success: false,
      };
    } catch (e) {
      return {
        success: false,
        data: e?.response?.data?.Message,
      };
    }
  }
}

export default new ApiHelper();
