/**
 * Colors which are used in the application are defined here first
 */
const colors = {
    primary: '#2196f3',
    primaryLight: '#6ecff',
    primaryDark: '#0068bf',
    white: '#fff',
    success: "#28a744",
    danger: 'red',
}

export default colors;