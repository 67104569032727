import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import { Update } from '@material-ui/icons';
import MailIcon from '@material-ui/icons/Mail';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

/**
 * Navigation bar component for the private routes
 * @param {{component: React.ReactNode}} param0 
 */
const Navbar = ({ component }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerList = [
    {
      title: 'Kullanıcılar Listesi',
      onPress: () => history.push('/users'),
      icon: <InboxIcon />
    },
    {
      title: 'Raporlar',
      onPress: () => history.push('/reports'),
      icon: <InboxIcon />
    },
    {
      title: 'İşlemler',
      onPress: () => history.push('/improves'),
      icon: <InboxIcon />
    },
    {
      title: 'Askıda Yardım',
      onPress: () => history.push('/donations'),
      icon: <InboxIcon />
    },
    {
      title: 'Raporlu Yorumlar',
      onPress: () => history.push('/reportedComments'),
      icon: <InboxIcon />
    },
    {
      title: 'Görevler',
      onPress: () => history.push('/missions'),
      icon: <InboxIcon />
    },
    {
      title: 'Veterinerler',
      onPress: () => history.push('/vets'),
      icon: <Update />
    },
    {
      title: 'Görev Ekle',
      onPress: () => history.push('/missionsBuilder'),
      icon: <Update />
    },
    {
      title: 'Sistemdeki Görevler',
      onPress: () => history.push('/allMissions'),
      icon: <Update />
    },
    {
      title: 'Sistemdeki Topluluklar',
      onPress: () => history.push('/allSocieties'),
      icon: <Update />
    },
    {
      title: 'Rozetler',
      onPress: () => history.push('/rosettes'),
      icon: <Update />
    },
  ]

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Helpimal
            </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {drawerList.map((item, index) => (
            <ListItem onClick={item.onPress} button key={item.title}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {component}
      </main>
    </div>
  );
}

export default Navbar;