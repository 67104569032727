
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePaginationActions from './PaginationFooter';
import TableHead from '@material-ui/core/TableHead';
import { colors } from '../../assets';
import { isFunction } from '../../helpers/utils';



const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: colors.primary,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
/**
 * Generic table component
 * you must give rows and columns a specific type
 * columns key must match with rows key
 * @param {any} props props of the table
 */
export default function CustomPaginationActionsTable(props) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const { rows, columns, pagination, pageSize, onPageChange } = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(pagination && pageSize ? pageSize : 0);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if(isFunction(onPageChange)) onPageChange(newPage, rowsPerPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        {columns.map((item, index) =>
                            <StyledTableCell key={item.key}>{item.text}</StyledTableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, index) => {
                        return (
                            <TableRow key={index.toString()}>
                                {
                                    columns.map((item, index) =>
                                        <TableCell key={item.key} component="th" scope="row">
                                            {row[item.key]}
                                        </TableCell>
                                    )
                                }
                            </TableRow>)
                    }
                    )}

                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                {pagination && 
                (<TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>)}
            </Table>
        </TableContainer>
    );
}

CustomPaginationActionsTable.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    pagination: PropTypes.bool,
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func
  };