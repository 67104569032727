import React, { useState, useCallback } from 'react';
import {
  CssBaseline,
  Container,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

/**
 * Login page of the application
 */
const RedirectPage = () => {

  const classes = useStyles();
  const thisUrl = window.location.href;
  const splitedUrl = thisUrl.split("/");
  const sendingData = splitedUrl[splitedUrl.length - 1];
  return (
    <div className={classes.container}>
      <Container>
        <div className={classes.content}>
          <Typography className={classes.header} variant="h5">
            Helpimal
                    </Typography>
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <a href={"helpimal://query/" + sendingData} className={classes.paper}>Uygulamayı Açmak İçin Tıklayınız</a>
          </Container>
        </div>
      </Container>
    </div>
  );
}

export default RedirectPage;