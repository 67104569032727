import React from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { Landing, RedirecPage } from "../pages/public";
import { Navbar } from "../components";
import SessionHelper from "../helpers/SessionHelper";

const auth = [

  {
    path: "/redirect",
    component: RedirecPage,
  },
];

const privateRoutes = [

];

/**
 * We write the private routes here
 * Id user is not logged in the system, the user cannot see this routes
 * @param {*} param0 params of the component
 */
const PrivateRoute = ({ children, ...rest }) => {
  const isLoggedIn = SessionHelper.getIsLoggedIn();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/redirect",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={Landing}></Route>
          {auth.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact}>
              <route.component />
            </Route>
          ))}
          {/* <PrivateRoute> */}
          {privateRoutes.map((route, index) => (
            <PrivateRoute key={index} path={route.path} exact={route.exact}>
              <Navbar component={<route.component />} />
            </PrivateRoute>
          ))}
          {/* </PrivateRoute> */}
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
